import React, { Component } from 'react'
import { Layout } from 'core/components'
import { TravelLayout } from 'modules/travel/pages'
import { AAnimate } from 'shared/components/common'
import { queryParams, FacebookPixel, setupPixel } from 'core/utils'

let ReactPixel: FacebookPixel

type ViajeProps = PageProps

interface ViajeState {
  mounted: boolean
}

interface ViajeParams {
  code: string
}

class Viaje extends Component<ViajeProps, ViajeState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
    ReactPixel = setupPixel()
    ReactPixel?.pageView()
  }

  render() {
    const {
      pageContext: { locale },
      location
    } = this.props
    const { mounted } = this.state
    const params = queryParams<ViajeParams>(location)
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <TravelLayout reservationCode={params.code} />
        </AAnimate>
      </Layout>
    )
  }
}

export default Viaje
